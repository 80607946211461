/* html,
body {
  font-family: "DIN Next LT Arabic";
} */

.eng-font {
  font-family: "DIN Next LT Arabic Light";
}

.arb-font {
  font-family: "DIN Next LT Arabic";
}

.nav-custom {
  background: #fff;
}
.nav-custom ul li a {
  color: rgba(125, 73, 198);
  font-size: 15px;
}

.slick-dots li button:before {
  color: #fff !important;
}

.medical-inner {
  padding-bottom: 190px;
}

.transform-hero-img {
  transform: scaleX(-1);
}

.bg-blueGray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(263, 245, 249, var(--tw-bg-opacity));
}

.bg-blueGray-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 63, 63, var(--tw-bg-opacity));
}
.bg-hecta-600 {
  --tw-bg-opacity: 0.02;
  background-color: rgba(0,99,170, var(--tw-bg-opacity));
}

.bg-blueGray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(263, 39, 39, var(--tw-bg-opacity));
}
.bg-blueGray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.bg-hecta-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(125, 73, 198, var(--tw-bg-opacity));
}
.medical-inner {
  padding-bottom: 250px;
  padding-top: 250px;
}
.list-icon{
  margin: 0 8px;
}
